import { Component, OnInit } from '@angular/core';
import { SurveyService } from '@app/services';
import { NgxSpinnerService } from 'ngx-spinner';

import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.less']
})
export class ResultComponent implements OnInit {

  resultTab
  domaines:[][]
  currentIndex=0;
  notesTabs=[]
  radarLabelsTab=[]



  constructor(private surveyService:SurveyService,
              private spinner: NgxSpinnerService)
              { }

  ngOnInit(): void {
    this.currentIndex++
    this.spinner.show()
    this.getOneUserResult()
  }

  getOneUserResult():void{
    this.surveyService.getOneUserSurveyResult()
    .subscribe(
      res => {
        this.resultTab = res['data'];
        // console.log(this.resultTab, 'Tableau resultat')

        this.domaines=this.resultTab['td_note_domaines']
        // console.log(this.domaines, 'daraaaaaaaa')

        for(var i=0;i<=this.domaines.length;i++){
          this.radarLabelsTab.push(this.domaines[i]['domaine'])
          this.notesTabs.push(this.domaines[i]['note'])
          this.spinner.hide();
        }
      }
    )
  }
  public radarChartLabels: Label[] = this.radarLabelsTab;


  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      display: true,
      labels: {
          fontColor: ['#C80F2D'],
          fontSize:20,
      },


   },
   scale: {
     ticks:
     {
       beginAtZero: true,
       fontSize: 18,
       max: 10,
       min: 0,
       stepSize: 1

      },
    }


  };

  public radarChartData: ChartDataSets[] = [
    {
      data: this.notesTabs,
      label: 'Niveau de Maturité',
      backgroundColor: ['rgba(100, 100, 105, 0.5)'],
      borderColor: ['#C80F2D'],


    }

  ];
  public radarChartType: ChartType = 'radar';


}
