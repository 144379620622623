import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tp_niv_maturite } from '@app/models';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient,
    private messageService: MessageService) { }

  getAllMaturityLevels(): Observable<tp_niv_maturite[]> {
    return this.http.get<tp_niv_maturite[]>(`${environment.apiUrl}/maturites`).pipe(
      map(data => data['data']),
      catchError(this.handleError<tp_niv_maturite[]>('getMaturitiesLevel', [])),
    );
  }


  /** GET maturity level from the server */
  getMaturitiesLevel(): Observable<tp_niv_maturite[]> {
    return this.http.get<tp_niv_maturite[]>(`${environment.apiUrl}/maturites`)
      .pipe(
        tap(_ => this.log('fetched maturities level')),
        catchError(this.handleError<tp_niv_maturite[]>('getMaturitiesLevel', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} Echec: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a UsersService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`UsersService: ${message}`);
  }
}