<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.2/iconfont/material-icons.min.css"
    />
  </head>
  <body>
    <div class="navbar-home">
      <img src="assets/img/doc/home-images/logo-white.png" class="logo" />
      <nav>
        <ul id="menu-list">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/account/register']">
              <i class="material-icons">person_add</i> S'inscrire 
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/account/login']">
              <i class="material-icons">fingerprint</i> se connecter
            </a>
          </li>
         
        </ul>
      </nav>
      <img
        src="assets/img/doc/home-images/menu.png "
        class="menu-icon"
        id="menu-btn"
      />
    </div>
    <div class="main">
      <div class="left-col-home">
        <div class="content-box">
          <h2>
            BIENVENUE SUR LA PLATEFORME D’EVALUATION DIGITALE DE VOTRE
            ENTREPRISE
          </h2>
          <p>Votre entreprise est-elle au top de sa maturité digitale?</p>
            
          <div *ngIf="currentUser?.is_tested === true; else elseBlock">
            <div class="buttons">
              <button type="button" [routerLink]="['/survey/result-survey']">
                Accéder aux resultats<img
                  src="assets/img/doc/home-images/right-arrow.png"
                />
              </button>
            </div>
          </div>
          <ng-template #elseBlock>
            <div class="buttons">
              <button type="button" [routerLink]="['/survey/play-survey/1']">
                Commencer le questionnaire
                <img src="assets/img/doc/home-images/right-arrow.png" />
              </button>
            </div>
            <h4 class="text-center">Durée 15mn / 80 questions</h4>
          </ng-template>
        </div>
        <div class="social-links">
          <a href="https://www.facebook.com/axiangroup" target="_blank"
            ><img src="assets/img/doc/home-images/facebook.png"
          /></a>
          <a href="https://twitter.com/AxianGroup" target="_blank"
            ><img src="assets/img/doc/home-images/twitter.png"
          /></a>
          <a href="#"
            ><img
              src="assets/img/doc/home-images/instagram.png"
              target="_blank"
          /></a>
        </div>
      </div>
      <div class="right-col-home"></div>
    </div>
  </body>
</html>
