export class User {
    id : string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    company_nom ?:string;
    fonction ?:string;
    telephone ?:string;
    role_id ?:number;
    token ?: string;
    expires_in ?:number;
    company_adresse ?:string;
    company_code ?:string="AXIAN";
    company_country ?:string;
    company_type ?:string;
    company_city ?;string;
    activity_area_id ?:number;
    profile ?: string;
    code ?: number;
    is_tested ?: boolean;
}
