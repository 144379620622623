import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { Domaine, Questions, question_response, Responses } from '@app/models';
import { SurveyService } from '@app/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { Output, EventEmitter } from '@angular/core';
import { PlaySurveyComponent } from '../play-survey/play-survey.component';
import { Subscription } from 'rxjs';


@Component({
  selector: '',
  templateUrl: './current-domain-qst.component.html',
  styleUrls: ['./current-domain-qst.component.less']
})
export class CurrentDomainQstComponent implements OnInit {
  currentDomaine;
  questions;
  public domains: [];
  currentIndex
  domaineID;
  showLoader: boolean;
  id:any
  @Input()
  domaines:[];
  currentQuestions: [];
  pourcentage;
  data: any;
  subscription: Subscription;


  private question_response ?:question_response[]=[];
  td_responses: any = [];
  public resp: any = [];

  // @Input() globalPercentage: number;

  img : any = 'wp-content/uploads/2016/11/logo_orbus_paiement1-694x347.jpg';
  @Input()
  qcmProgressValue: number;
  // @Output() percentageEvent: EventEmitter<any> = new EventEmitter<string>();
  @Input()
  idDomaine: number;

  constructor( private surveyService: SurveyService,
                private route: Router,    private router: ActivatedRoute,
                private spinner: NgxSpinnerService) {
                }


  ngOnInit(): void {
     this.currentIndex = 0;
 this.domaineID=0

    this.router.paramMap.subscribe(params => {
      this.domaineID = params.get('domaineID');
      // Do more processing here if needed
      this.domaineID = this.router.snapshot.paramMap.get('id');
      this.surveyService.surveySourceID.next(this.domaineID);
      this.spinner.show()
      this.getQuestionsByDomainId();
      // this.getOneDomainPercentage();
      this.getPourcentageTotal();
    });

    // this.getAllDomains();
  }

  ngAfterViewInit() {
   this.getQuestionsfromService();
  }



  radioChange(event: MatRadioChange, resp) {

    var response = new question_response();

    var obj = this.resp.filter(x => x.id == resp.id)[0];
    obj = event.value;

    response.response_id = obj.id;
    this.surveyService.addSingleResponse(response).subscribe(
      resp => {
        this.pourcentage=resp.data;
        this.getPourcentageTotal();
        this.getAllDomaine();
      } ,
      error => {
        // console.log('erreur '+error);
      }
    );
  }

  public getOneDomainPercentage(){
    this.surveyService.getOneDomainPercentage(this.domaineID).subscribe(
      res => {
        this.pourcentage=res['data']
        // localStorage.setItem("singleDomainePercent",JSON.stringify(res['data']));
      }
    )
  }

  public getQuestionsByDomainId(){
    this.surveyService.getQuestionsByDomain(this.domaineID).subscribe(
      res => {
        this.questions = res['data'];
        // start time and spinner
        this.currentDomaine = this.questions[this.domaineID]
        this.surveyService.surveySourceQuestions.next(this.questions);
        this.spinner.hide();
        this.showLoader = true;

        // console.log()

      }
    );
  }


  // public getAllDomains() {
  //   this.surveyService.getAllDomainWithQuestionsResponses().subscribe(
  //     domains => {
  //       this.domains = domains['data']
  //       this.currentDomaine = this.domains[this.currentIndex]
  //       // this.spinner.hide();
  //     });
  // }


  onPrevious() {
    //this.getQuestionsByDomainId()
    if((this.domaineID - 1) >= 1){
      this.domaineID--
      this.route.navigate(['/survey/play-survey/'+this.domaineID]);
      this.surveyService.surveySource.next(this.domaineID);

      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, 0); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 4);
    }
  }
  nextStep() {
    // if((this.domaineID + 1) < 8){

    // }
    this.domaineID++
    
    this.route.navigate(['/survey/play-survey/'+this.domaineID]);
    this.surveyService.surveySourceID.next(this.domaineID);
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, 0); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 4);

  }
  getPourcentageTotal(){
    this.surveyService.getpourcentage().subscribe(
      res=>{
        this.qcmProgressValue = res.data;
        this.surveyService.surveySource.next(this.qcmProgressValue);
      }
    )
  }


  getAllDomaine(){
    this.surveyService.getAllDomains().subscribe(
      domains => {
        this.domaines = domains['data'];
        this.surveyService.surveySourceDomaines.next(this.domaines);
        console.log(this.surveyService.surveySourceDomaines.value,"SURVEY")
      });
  }


  getQuestionsfromService() {
    this.surveyService.surveySourceQuestions.subscribe(
      data => {
        this.questions = data;
      }, (error) =>  console.log(error)
    )
  }


  

}
