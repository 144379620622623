import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import { User } from '@app/models';
import { AccountService } from '@app/services';
import { first } from 'rxjs/operators';
import { SigninComponent } from '@app/account/signin/signin.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
      mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    currentUser: User;
    users: User[] = [];

    user: User;


    constructor(location: Location,
                private element: ElementRef,
                public router: Router,
                private accountService: AccountService) {
        this.location = location;
        this.sidebarVisible = false
        this.currentUser = this.accountService.userValue;
        this.user = this.accountService.userDecodedValue;
    }

    ngOnInit(){
    //   this.getConnectedUserInfo();
      // this.listTitles = ROUTES.filter(listTitle => listTitle);

      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.router.events.subscribe((event) => {
        this.sidebarClose();
         var $layer: any = document.getElementsByClassName('close-layer')[0];
         if ($layer) {
           $layer.remove();
           this.mobile_menu_visible = 0;
         }
     });
    }


    SignOut() {
        this.accountService.logout();
    }

    sidebarOpen() {
    };
    sidebarClose() {
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

 
}
