import { Deserializable } from './deserializable.model';
import { Questions } from './question.model';

export class Domaine implements Deserializable{
    id : number;
    domaine_code:string;
    doamine_name: string;
    domaine_description: string;
    pourcentage:number;
    td_questions: Questions[];

    deserialize(input: any) {
        Object.assign(this, input);
        // this.td_questions=new Questions().deserialize(input.td_questions);
        return this;
    }
}