import { Component, OnInit } from '@angular/core';
import { tp_niv_maturite } from '@app/models';
import { UsersService } from '@app/services';

@Component({
  selector: 'app-maturity-level',
  templateUrl: './maturity-level.component.html',
  styleUrls: ['./maturity-level.component.less']
})
export class MaturityLevelComponent implements OnInit {
  public maturities:tp_niv_maturite[];
  currentIndex=0;
  constructor(private usersService:UsersService) { }

  ngOnInit(): void {
    this.currentIndex=0
    this.getAllMaturityLevels()
    this.currentIndex++;
  }
  public getAllMaturityLevels() {
    this.usersService.getAllMaturityLevels()
    .subscribe(
      maturities =>{
        this.maturities= maturities
        // console.log(this.maturities, 'ALL MATURITIES')
      });
  }
}
