import { Deserializable } from "./deserializable.model";


export class Responses implements Deserializable {
    id: number;
    response_wording: string;
    note: number;
    question_id: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}