import { Component } from '@angular/core';

import { AccountService } from './services';
import { User } from './models';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user: User;
    returnUrl:string;
    userSubcription: Subscription;

    constructor(private accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute) {
        this.userSubcription = this.accountService.currentUser.subscribe(x => this.user = x);
    }

    ngOnInit(): void {
        console.log('auth : ' + this.accountService.isAuthentificated());
    }
    
    tokenExpire(){
        if(this.user.expires_in == 3600){
            // this.router.navigate(['/account/login'],
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'account/login';
            this.router.navigateByUrl(this.returnUrl);
            setTimeout(() => {
                this.userSubcription.unsubscribe();        
            }, 1000);
        }
    }

    ngOnDestroy(): void {
        this.userSubcription.unsubscribe();        
        // this.tokenExpire()
    }
}