
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C80F2D" type="ball-scale-multiple" [fullScreen]="true">
  <p style="color: #fff"> Chargement... </p>
</ngx-spinner>
<app-navbar></app-navbar>
<br><br><br>

<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header card-header-tabs card-header-rose" >
        <div class="nav-tabs-navigation">
          <div class="nav-tabs-wrapper">
            <h4 style="font-weight: bold;">Evaluation globale</h4>
            <div class="progress">
              <div class="progress-bar" role="progressbar" [attr.aria-valuenow]="qcmProgressValue" aria-valuemin="0" aria-valuemax="100"
                [style]="'width:' + qcmProgressValue  + '%'">
                {{ qcmProgressValue }}%
              </div>
            </div>
          </div>
        </div> 
      </div>
      <div class="card-body">
        <div class="row" style="margin-top: 50px;">
          <div class="col-md-2 domaine ">
            <div [class]="scrollingSidebar()">
              <ul class="flex-column " role="tablist" id="navigation">
                <li class="nav-item domaineli" *ngFor="let d of domaines">
                  <a class="domaineLink" data-toggle="tab" href="" role="tablist"
                    (click)="selectDomaine(d.id)"  [style]="brillance(d.id)">
                    {{d.domaine_name | uppercase}}<span style="font-weight: bold;"> ({{d.pourcentage}}%)</span> 
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-10">
            <router-outlet></router-outlet>

          </div>
        </div>
      </div>
      <mat-card-actions>
        <mat-action-row class="float-right submitbuttoncontainer">
          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-md-6">
                  <div *ngIf="qcmProgressValue==='100.0'">
                    <button type="button" class="btn btn-default submitbutton" (click)="showSwal('submit-test')">Valider le test</button>
                  </div>
                </div>
                <div class="col-md-6">
                  <button type="button" class="btn btn-default submitbutton" (click)="showSwal('restart-test')">Recommencer le test</button>
                </div>
              </div>
            </div>

          </div>
        </mat-action-row>
      </mat-card-actions>


    </div>

<!--
    <div *ngIf="qcmProgressValue; else elseBlock">

      <div class="col-md-6">
        <button type="button" class="btn btn-info submitbutton" (click)="onSubmit()">Valider le test</button>
      </div>
      <ng-template #elseBlock>
        <div class="row">
          <div class="col-sm-6">
            <button type="button" class="btn btn-info submitbutton" (click)="onSubmit()">précedent</button>
          </div>
          <div class="col-sm-6">
            <button type="button" class="btn btn-info submitbutton" (click)="onSubmit()">suivant</button>
          </div>
        </div>
      </ng-template>
    </div> -->




