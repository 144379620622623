

  <div *ngIf="currentUser">
    <nav  class="navbar navbar-expand-lg  bg-primary navbar-absolute" style="z-index: auto;" >
        <div class="container-fluid">
            <div class="navbar-wrapper">
                <a class="navbar-brand" href="javascript:void(0)" [routerLink]="['/home']"><img src="../../../assets/img/doc/home-images/logo-white.png" class="logotransparente" alt=""></a>
            </div>
            <!-- <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="navbar-toggler-icon icon-bar"></span>
                <span class="navbar-toggler-icon icon-bar"></span>
                <span class="navbar-toggler-icon icon-bar"></span>
              </button> -->
            <div class="collapse navbar-collapse justify-content-end" id="navigation">

                <ul class="navbar-nav">
                    <li class="nav-item">

                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="material-icons">person</i>{{user.first_name}}
                            {{user.last_name}}
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <a class="dropdown-item"  (click)="SignOut()">Déconnexion</a>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    </nav>
</div>


